import { useRef, useState, useEffect } from 'react';
import styles from '../styles/ShowcaseScroll.module.css';
import TechnicalShowcaseProps from '../interfaces/Project';

export default function TechnicalShowcaseScroll({ index, project }: { index: number; project: TechnicalShowcaseProps }) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(true);
          entry.target.scrollIntoView({ behavior: 'smooth', block: 'end', });
        } else {
          setVisible(false);
        }
      });
    }, {
      threshold: 0.2
    });
  
    const currentRef = domRef.current;
    if (currentRef) {
      observer.observe(currentRef);
      return () => observer.unobserve(currentRef);
    }
  }, []);
  

  return (
    <section
      aria-label="showcase-element"
      key={index}
      className={`${styles.section} ${styles.fadeInSection} ${isVisible ? styles.isVisible : ''}`}
      ref={domRef}
    >
      <div className={styles.wrapper}>
        <article aria-label="project-header" className={styles.body}>
          <a className={styles.a} href={project.link} target="_blank" rel="noreferrer">
            <header className={styles.header}>{project.title}</header>
          </a>
          <img className={styles.img} src={project.image} alt={project.title + ' image'} />
        </article>
        <article aria-label="project-sidebar" className={styles.sidebar}>
          <p className={styles.p}>{project.description}</p>
        </article>
      </div>
    </section>
  );
}
