import { useLayout } from './PortfolioContext';
import NavLink from './NavLink';
import { faYoutube, faTwitch, faGithub } from '@fortawesome/free-brands-svg-icons';
import styles from "../styles/PortfolioNavBar.module.css";

export default function PortfolioNavbar() {
  const { layout, toggleLayout } = useLayout();

  return (
    <section aria-label="navbar" className={styles.navbar}>
      <a href="#/" id={styles.explore}>Sayarin's Portfolio</a>
      <div className={styles.navItemsContainer}>
      <p aria-label="layout-text" className={styles.toggleText}>{`${layout} VIEW`}</p>

        <label className={styles.switch}>
          <input
            aria-label="layout-switch"
            type="checkbox"
            checked={layout === 'SCROLL'}
            onChange={toggleLayout}
          />
          <span className={styles.slider}></span>
        </label>

        <nav aria-label="links" className={styles.navLinks}>
          <NavLink aria-label="navigation-link" link='https://github.com/Sayarinu' icon={faGithub}/>
          <NavLink aria-label="navigation-link" link='https://www.twitch.tv/sayarinu' icon={faTwitch}/>
          <NavLink aria-label="navigation-link" link='https://www.youtube.com/channel/UCaylK98TZdelPYG5OUaQGlA' icon={faYoutube}/>
        </nav>
      </div>
    </section>
  );
}