import PortfolioNavbar from "./PortfolioNavbar";
import { useLayout } from './PortfolioContext';
import PortfolioGrid from "./PortfolioGrid";
import PortfolioScroll from "./PortfolioScroll";
import projects from "../json-data/projects"

export default function PortfolioPage() {
  const { layout } = useLayout();

  return (
    <main aria-label="main-section">
      <PortfolioNavbar/>
      {layout === "SCROLL" ? <PortfolioScroll projects={projects}/> : <PortfolioGrid projects={projects}/> }
    </main>
  );
}
