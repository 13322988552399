import HomeNavBar from "./HomeNavBar";
import styles from "../styles/Home.module.css";

export default function Home() {
  return (
    <main className={styles.main}>
      <HomeNavBar />
      <section aria-label="main-body" className={styles.wrapper}>
        <article className={styles.body}>
          <h1 className={styles.h1}>Hi, I am Sayarin.</h1>
          <p className={styles.p}>I am a software developer based in the United States. I enjoy programming in all sorts of projects.</p>
          <p className={styles.p}>The homebrew community is one of the communities that I have come to know and love and I would love end up contributing back to the people who have spent their time developing such amazing software that I and many others have enjoyed. With my skillset I am aiming to be able to help others enjoy new software to help them explore their creativity.</p>
          <p className={styles.p}>I like to work interdisciplinary and have a passion for photography, game development and piano. As you browse what my website contains I hope you enjoy some of my works.</p>
          <p className={styles.p}>Gaming has always been one of my passions, both casually playing a single player game or playing and competing at a high level in League of Legends.</p>
        </article>
        <article aria-label="explore" className={styles.sidebar}>
          <a href="#/Portfolio" id={styles.explore}>Explore My Works</a>
        </article>
      </section>
    </main>
  );
}
