import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from "../styles/NavLink.module.css"

interface NavLinkProps {
  link: string;
  icon: any;
}

export default function NavLink({ link, icon }: NavLinkProps) {
  return (
    <a href={link} target='_blank' rel="noreferrer">
      <FontAwesomeIcon icon={icon} className={styles.navbarIcons}/>
    </a>
  );
}