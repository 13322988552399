const projects = [
  {
    "title": "Sayalita",
    "image": "/images/Sayalita.png",
    "link": "https://github.com/Sayarinu/OpenGL-3D-Graphics-Sphere-Showcase",
    "description": "Discord Bot that allows you and your friends to make wagers on each other and collect points to use." 
  },
  {
    "title": "HAAK",
    "image": "/images/HAAK.PNG",
    "link": "https://github.com/Sayarinu/Helpful-Automated-Assistant-For-Kitchens",
    "description": "HAAK is a chatbot-like service that provides users with personalized dining options based on their location, time of day, and previous dining experiences. The system will use speech-to-text and direct text input to aid in user interactions and provide users relevant information in a simple and digestible format." 
  },
  {
    "title": "Robomaster Simulation",
    "image": "/images/Robomaster Sim.png",
    "link": "https://github.com/Sayarinu/Robomaster-Simulation",
    "description": "Simulation of the Robomaster NA Competition to help the team practice and prepare for the competition." 
  },
  {
    "title": "OpenGL Sphere Demo",
    "image": "/images/OpenGL Sphere.png",
    "link": "https://github.com/Sayarinu/OpenGL-3D-Graphics-Sphere-Showcase",
    "description": "OpenGL 3D Sphere Rolling simulation showcase of lighting effects, texture mapping, matrix transformations, and more." 
  }
]

export default projects;