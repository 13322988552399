import React from 'react';
import ReactDOM from 'react-dom/client';
import ErrorPage from "./components/ErrorPage";
import PortfolioPage from './components/PortfolioPage';
import { PortfolioContext } from './components/PortfolioContext'
import Home from "./components/Home"
import '@fortawesome/fontawesome-free/css/all.css';
import { createHashRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

const router = createHashRouter([
  {
    path: "",
    element: <Home/>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/Portfolio",
    element: <PortfolioContext><PortfolioPage/></PortfolioContext>,
    errorElement: <ErrorPage/>
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


reportWebVitals();
