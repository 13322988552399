import styles from '../styles/ShowcaseGrid.module.css';
import TechnicalShowcaseProps from '../interfaces/Project';

export default function TechnicalShowcase({ index, project }: { index: number; project: TechnicalShowcaseProps }) {
  return (
    <>
      <article aria-label="project-header" className={styles.body}>
        <a className={styles.a} href={project.link} target="_blank" rel="noreferrer">
          <header className={styles.header}>{project.title}</header>
        </a>
        <img className={styles.img} src={project.image} alt={project.title + ' image'} />
        <p className={styles.p}>{project.description}</p>
      </article>
    </>
  );
}
