import TechnicalShowcaseScroll from "./TechnicalShowcaseScroll";
import TechnicalShowcaseProps from "../interfaces/Project";

export default function PortfolioScroll({ projects }: { projects: TechnicalShowcaseProps[] }) {
  return (
    <>
      {projects.map((project: TechnicalShowcaseProps, index: number) => (
        <TechnicalShowcaseScroll 
          key={index} 
          index={index} 
          project={project} 
        />
      ))}
    </>
  );
}
