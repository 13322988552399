import NavLink from './NavLink';
import styles from '../styles/HomeNavBar.module.css'
import { faYoutube, faTwitch, faGithub } from '@fortawesome/free-brands-svg-icons';

export default function HomeNavBar() {
  return (
    <nav aria-label="navbar" className={styles.navbar}>
      <h1 className={styles.navbarTitle}>Sayarin's Portfolio</h1>
      <nav aria-label="navbar-links">
        <NavLink link='https://github.com/Sayarinu' icon={faGithub}/>
        <NavLink link='https://www.twitch.tv/sayarinu' icon={faTwitch}/>
        <NavLink link='https://www.youtube.com/channel/UCaylK98TZdelPYG5OUaQGlA' icon={faYoutube}/>
      </nav>
    </nav>
  );
}