import TechnicalShowcaseGrid from "./TechnicalShowcaseGrid";
import styles from '../styles/ShowcaseGrid.module.css'
import TechnicalShowcaseProps from "../interfaces/Project";

export default function PortfolioGrid({ projects } : { projects: TechnicalShowcaseProps[] } ) {
  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
      {projects.map((project: TechnicalShowcaseProps, index: number) => (
        <TechnicalShowcaseGrid 
          key={index} 
          index={index} 
          project={project} 
        />
      ))}
      </div>
    </section>
  );
}