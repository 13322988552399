import { createContext, useContext, useState, ReactNode } from "react";

type LayoutStyles = 'SCROLL' | 'GRID';

interface LayoutContextProps {
  layout: LayoutStyles;
  toggleLayout: () => void;
}

const LayoutContext = createContext<LayoutContextProps | undefined>(undefined);

export const PortfolioContext: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [layout, setLayout] = useState<LayoutStyles>('SCROLL');

  const toggleLayout = () => {
    setLayout((prevLayout) => (prevLayout === 'SCROLL' ? 'GRID' : 'SCROLL'));
  };

  return (
    <LayoutContext.Provider value={{ layout, toggleLayout }}>
      {children}
    </LayoutContext.Provider>
  );
}

export const useLayout = (): LayoutContextProps => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a PortfolioContext');
  }
  return context;
};